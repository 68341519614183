.ant-carousel .slick-slide {
  height: 100vh;
  overflow: hidden;
}

.slide {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #fff;
}
.slide1 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #8fcfea;
  color: #fff;
}
.slide2 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #abb5d3;
  color: #fff;
}
.slide3 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #9dc3a7;
  color: #fff;
}
.slide4 {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #daa6b9;
  color: #fff;
}

.ant-carousel img {
  width: 40vw;
}

.ant-carousel h1 {
  color: white;
  margin-top: 30px;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0;
}

.ant-carousel p {
  color: white;
  font-size: 16px;
}

.ant-carousel .slick-dots {
  margin-bottom: 50px;
}
