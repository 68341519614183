.loan table {
    width: 100%;
}

.loan th {
    background-color: #f3f3f3;
}

.loan table,
.loan th,
.loan td {
    border: 1px solid #dddddd;
    padding: 10px;
    text-align: center;
}
