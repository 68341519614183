/* Root Components */
footer {
    font-size: 14px !important;
    font-family: "brandon_textmedium", sans-serif;
}

#loading {
    position: absolute;
    z-index: 999;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #000d;
}

/* Menu Settings */
.ant-menu-horizontal .ant-menu-item {
    border: none !important;
}

.ant-layout-sider-children, .ant-menu {
  background-color: #09A5E6 !important;
}

.ant-menu-item-selected {
  background-color: #0003 !important;
}

.ant-select-dropdown {
  z-index: 9999999 !important;
}

.ant-menu-submenu-title span {
  color: #fff;
}


/* Do not show up when printing */
@media print {
    .doNotPrint {
        visibility: hidden;
        display: none;
    }
}
