@font-face {
    font-family: "brandon_textbold";
    src: url("./hvd_fonts_-_brandontext-bold-webfont.woff2") format("woff2"), url("./hvd_fonts_-_brandontext-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "brandon_textregular";
    src: url("./hvd_fonts_-_brandontext-regular-webfont.woff2") format("woff2"), url("./hvd_fonts_-_brandontext-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "brandon_textmedium";
    src: url("./hvd_fonts_-_brandontext-medium-webfont.woff2") format("woff2"), url("./hvd_fonts_-_brandontext-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "brandon_textblack";
    src: url("./hvd_fonts_-_brandontext-black-webfont.woff2") format("woff2"), url("./hvd_fonts_-_brandontext-black-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
